<template>
	<!--	<ApplicationsHeader />-->
	<div class="mx-auto max-w-3xl my-6 px-6 text-left mt-10">
		<h1 class="text-2xl font-bold text-center">
			Get ready for February - IMPRO Amsterdam is BACK!
		</h1>
		<div class="mt-4 text-lg text-center">
			<FestivalDates /> at
			<a
				href="https://maps.app.goo.gl/vznZib838RG9Y7Eg6"
				class="impro-link underline"
				>'t Zonnehuis, Amsterdam Noord</a
			>
		</div>
		<p class="text-2xl font-semibold mt-12 mb-8 text-center">
			We need <nuxt-link to="/applications/volunteers/"
			><span class="impro-link underline"
			>your help</span
			></nuxt-link> to make the festival happen!
		</p>

		<hr class="mt-8" />
		<pretix-widget :event="path" :items="303" disable-vouchers> </pretix-widget>
		<hr class="mt-4" />
		<dl>
			<div class="text-left">
				<Disclosure as="div" class="pt-6" v-slot="{ open }">
					<dt>
						<DisclosureButton class="flex items-start text-left text-gray-900">
							<span class="text-xl font-semibold mt-4"
								>New to improvisational theatre?</span
							>
							<span class="ml-6 flex h-16 items-center">
								<PlusSmallIcon
									v-if="!open"
									class="size-6 h-5 w-5"
									aria-hidden="true"
								/>
								<MinusSmallIcon
									v-else
									class="size-6 h-5 w-5"
									aria-hidden="true"
								/>
							</span>
						</DisclosureButton>
					</dt>
					<DisclosurePanel as="dd" class="mt-2 pr-12">
						<p class="mt-4">
							Here’s the deal. Just like scripted theatre, improvisational
							theatre can be comedic, dramatic, abstract, topical… really
							anything. The difference is that in improvisational theatre (also
							known as impro or improv) there is no script.
						</p>
						<p class="mt-2">
							Players might have a sense of the structure of the show they’re
							going into, or maybe a time period, but their characters, lines,
							and even the story itself are all created on the spot.
						</p>
						<p class="text-lx font-semibold mt-6">
							Not convinced yet? Check out some stats.
						</p>
						<ul class="list-none mb-4">
							<li class="ml-2">
								<b>5</b> - number of continents represented by our teachers and
								performers
							</li>
							<li class="ml-2">
								<b>20</b> - the size of our festival cast, a mix of
								international performers and some of the top performers in the
								Netherlands
							</li>
							<li class="ml-2">
								<b>30</b> - age of IMPRO Amsterdam (older than many attendees)
							</li>
							<li class="ml-2">
								<b>30</b> - number of shows during the festival
							</li>
							<li class="ml-2">
								<b>60</b> - number of workshops, with topics ranging from core
								impro skills to drag and puppetry
							</li>
						</ul>
					</DisclosurePanel>
				</Disclosure>
				<hr class="mt-4" />
				<p class="text-xl font-semibold mt-4">So what’s the lineup?</p>
				<p class="mt-2">
					Click on thumbnail to open \ download the full schedule
				</p>
				<div class="flex justify-between mt-4 mb-2">
					<a
						:href="`${mediaImagesPath}timetable_shows__2025.original.png`"
						target="_blank"
					>
						<img
							:src="`${mediaImagesPath}timetable_shows_small__2025.original.png`"
							alt=""
						/>
					</a>

					<a
						:href="`${mediaImagesPath}timetable_workshops__2025.original.png`"
						target="_blank"
					>
						<img
							:src="`${mediaImagesPath}timetable_workshops_small__2025.original.png`"
							alt=""
						/>
					</a>
				</div>
			</div>
		</dl>
	</div>

	<!-- TODO move this to a separate component -->
	<!--	<div class='bg-white'>-->
	<!--		<div class='mx-auto max-w-7xl px-6 lg:px-8'>-->
	<!--			<div class='mx-auto mt-16 max-w-2xl sm:mt-8 lg:mt-8 lg:max-w-4xl'>-->
	<!--				<dl class='grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16'>-->
	<!--					<div v-for='application in store.applications' :key='application.name' class='relative pl-16'>-->
	<!--						<dt class='text-base font-semibold leading-7 text-gray-900'>-->
	<!--							<a :href='application.href' class="impro-link underline">{{ application.name }}</a>-->
	<!--						</dt>-->
	<!--						<dd class='mt-2 text-base leading-7 text-gray-600'>{{ application.description }}</dd>-->
	<!--					</div>-->
	<!--				</dl>-->
	<!--			</div>-->
	<!--		</div>-->
	<!--	</div>-->

	<!--	<Partners />-->
	<div class="bg-white px-6 py-8 lg:px-8">
		<MailChimpForm />
	</div>
</template>
<script setup lang="ts">
import { useApplicationsStore } from '~/store/applications'
import ApplicationsEnded from '../components/ApplicationsEnded.vue'
import ApplicationsHeader from '../components/ApplicationsHeader.vue'
import MailChimpForm from '~/components/MailChimpForm.vue'
import FestivalDates from '~/components/FestivalDates.vue'
import { useApiConfigStore } from '@/store/apiConfigStore'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const store = useApplicationsStore()
const apiConfigStore = useApiConfigStore()
const mediaImagesPath: ComputedRef<string> = computed(
	() => `${apiConfigStore.REST_ENDPOINT}/media/images/`,
)
const path: ComputedRef<string> = computed(() => {
	const endpoint = apiConfigStore.REST_ENDPOINT.replace('https://', '')
	return `https://tickets.${endpoint}/festival/2025/`
})
onMounted(async () => {
	setTimeout(async () => {
		// @ts-ignore: Unreachable code error
		window.PretixWidget.buildWidgets() // eslint-disable-line
	}, 1)
})
</script>

<style>
.pretix-widget.pretix-widget-mobile object[aria-hidden='true'] {
	height: 0px !important;
}
.pretix-widget .pretix-widget-action button,
.pretix-widget .pretix-widget-voucher-button-wrap button {
	background-color: #212951 !important;
	border-color: #212951 !important;
}
.sold-out {
	color: #fb600e;
}
</style>
